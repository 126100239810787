import axios from 'axios';
import AuthService from './auth-header';
// /*dev*/const API_URL = 'http://192.168.1.81:5000/api/';
// /*prod*/ //const API_URL = 'https://ats.sattvahuman.in/api/';

let API_URL = ''
if (process.env.NODE_ENV === "production") {
    API_URL = 'https://ats.sattvahuman.in/api/';
} else {
    API_URL = 'http://192.168.1.81:5000/api/';
}
class DashboardService {
    
    async getUserDashboard(range, recruiter, mgr_id, role) {
        try {
            const res = await axios.get(API_URL + 'dashboard/user/' + role + '?recruiter=' + recruiter + '&mgr_id=' + mgr_id + '&range=' + range,
            {
                headers: AuthService.authHeader()
            })
            console.log(res.data)
            return res.data
            
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }

    }
    async getRevenueDashboard(startDate, endDate) {
        try {
            const res = await axios.get(API_URL + 'dashboard/revenue?startDate=' + startDate + '&endDate=' + endDate,
            {
                headers: AuthService.authHeader()
            })
            console.log(res.data)
            return res.data
            
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }

    }
    async getMainDashboard(startDate, endDate, role, emp_id ,location, mgr_id) {
        try {
            
            const res = await axios.get(API_URL + 'dashboard/main?startDate=' + startDate + '&endDate=' + endDate + '&emp_id=' + emp_id + '&role=' + role + '&location=' + location + '&mgr_id=' + mgr_id,
            {
                headers: AuthService.authHeader()
            })
            console.log(res.data)
            return res.data
            
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }

    }
}

export default new DashboardService();